import React from 'react';
import logo from './logo.svg';
import './App.css';
import eCommWeb from './assets/img/eCommWeb.jpg';
import sortvis from './assets/img/sortvis.jpg';
import irispred from './assets/img/irispred.jpg';


function App() {
  return (
    <div className="App">


        <nav className="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar">


            <div className="container">

            
                <a className="navbar-brand" href="#" target="_self">
                    <strong>IB</strong>
                </a>

            
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

            
                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                    
                    <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="#">Home
                        <span className="sr-only">(current)</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#projectsec" target="_self">Projects</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#workex" target="_self">Work Experience</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#education" target="_self">Education</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#contactdetails" target="_self">Contact</a>
                    </li>
                    </ul>

                    
                    <ul className="navbar-nav nav-flex-icons">
                    <li className="nav-item">
                        <a href="https://www.linkedin.com/in/ishanibhansali/" className="nav-link border border-light rounded" 
                        target="_blank" style={{marginLeft: 5, marginRight: 5}}>
                        <i className="fab fa-linkedin"></i>LinkedIn
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="https://github.com/ishanibhansali" className="nav-link border border-light rounded"
                        target="_blank" style={{marginLeft: 5, marginRight: 5}}>
                        <i className="fab fa-github mr-2"></i>GitHub
                        </a>
                    </li>
                    </ul>


                </div>


            </div>


        </nav>
      
        
        <div id="carousel-example-1z" className="carousel slide carousel-fade" data-ride="carousel">

            
            <div className="carousel-inner" role="listbox">

                
                <div className="carousel-item active">


                    <div className="view" style={{backgroundImage: "url('https://mdbootstrap.com/img/Photos/Others/images/77.jpg')", backgroundRepeat: "no-repeat", height: 1000}}>

                        
                        <div className="mask rgba-black-light d-flex justify-content-center align-items-center">

                                
                            <div className="text-center white-text mx-5 wow fadeIn">
                                <h1 className="mb-4">
                                    <strong>Hi, I'm Ishani Bhansali</strong>
                                </h1>

                                <p>
                                    <strong>I'm a software engineer and a data enthusiast, passionate about creating products that bring joy to the user 
                                        while adding value to businesses.</strong>
                                </p>

                                <p className="mb-4 d-none d-md-block">
                                    <strong>I have a diverse set of skills, ranging from Python, Java, JavaScript, all the way to database systems 
                                        such as SQL and cloud computing services such as AWS and IBM Cloud.</strong>
                                </p>

                            </div>
                                

                        </div>
                        

                    </div>


                </div>
                
            
            </div>
                

        </div>
        

        <main>


            <div className="container">

            
                <section>


                    <h3 id="projectsec" className="h3 text-center mb-5" style={{marginTop:100}}>Projects</h3>

                    
                    <div className="row wow fadeIn">

                    
                        <div className="col-lg-6 col-md-12 px-4" style={{marginBottom:75}}>

                            
                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Description</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• Employed Django Web-framework, HTML, CSS, JavaScript and SQL to develop a fully functional and interactive eCommerce website.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Incorporated features such as user authentication and registration using Django's Allauth library.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Implemented a database system for the website with CRUD functionality to handle orders from each user.</p>
                            </div>
                            </div>
                             
                            

                            <div style={{height: 30}}></div>

                            
                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Technologies Utilized</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• Django, HTML, CSS, JavaScript and SQL</p>
                            </div>
                            </div>

                            <div style={{height: 30}}></div>
                            <a href="https://github.com/ishanibhansali/eCommerce-Website" target="_blank">
                            <button className="btn purple-gradient">View Project</button></a>
                            

                        </div>
                    

                        <div className="col-lg-6 col-md-12" style={{marginBottom:75}}>

                            <p className="h5 text-center mb-4">eCommerce Website</p>
                            <div className="embed-responsive embed-responsive-16by9">
                            <img className="embed-responsive-item" src={eCommWeb}></img>
                            </div>
                        </div>


                        <div className="col-lg-6 col-md-12" style={{marginTop:75, marginBottom:75}}>

                            <p className="h5 text-center mb-4">Sorting Algorithm Visualizer</p>
                            <div className="embed-responsive embed-responsive-16by9">
                            <img className="embed-responsive-item" src={sortvis}></img>
                            </div>
                        </div>


                        <div className="col-lg-6 col-md-12 px-4" style={{marginTop:75, marginBottom:75}}>

                            
                            <div className="row">
                                <div className="col-1 mr-3">
                                    
                                </div>
                                <div className="col-10">
                                    <h5 className="feature-title">Description</h5>
                                    <p className="grey-text" style={{textAlign: "left"}}>• Created a web-app using ReactJS to visualize different sorting algorithms in actions.</p>
                                    <p className="grey-text" style={{textAlign: "left"}}>• Visualized merge sort, quick sort, heap sort, bubble sort and selection sort to illustrate 
                                    their mechanism of sorting randomly generated data and their time complexities.</p>
                                    <p className="grey-text" style={{textAlign: "left"}}>• Designed a visually appealing and intuitive front-end using CSS3 and HTML5.</p>
                                </div>
                            </div>
                            

                            
                            

                            <div style={{height: 30}}></div>

                            
                            <div className="row">
                                <div className="col-1 mr-3">
                                    
                                </div>
                                <div className="col-10">
                                    <h5 className="feature-title">Technologies Utilized</h5>
                                    <p className="grey-text" style={{textAlign: "left"}}>• ReactJS, HTML5, CSS3</p>
                                </div>
                            </div>

                            <div style={{height: 30}}></div>

                            <a href="https://ishanibhansali.github.io/Sorting-Algorithm-Visualizer/" target="_blank">
                            <button className="btn purple-gradient">View Project</button></a>
                            

                        </div>
                    

                        <div className="col-lg-6 col-md-12 px-4" style={{marginTop:75, marginBottom:75}}>

                            
                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Description</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• Developed a Machine Learning enabled app using Python and Django Web-framework to predict iris flower species from user input.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Performed data cleaning and feature engineering on the Iris Flower dataset and tested several models to predict the species of iris flower with an accuracy of over 99% with the SVM algorithm.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Used AJAX to take input from the user and return the results.</p>
                            </div>
                            </div>


                            

                            <div style={{height: 30}}></div>


                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Technologies Utilized</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• Python (Pandas, Scikit-Learn), Django, SQL, AJAX</p>
                            </div>
                            </div>

                            <div style={{height: 30}}></div>

                            <a href="https://github.com/ishanibhansali/Iris-Prediction" target="_blank">
                            <button className="btn purple-gradient">View Project</button></a>

                        </div>


                        <div className="col-lg-6 col-md-12" style={{marginTop:75, marginBottom:75}}>

                            <p className="h5 text-center mb-4">Iris Prediction Web-App</p>
                            <div className="embed-responsive embed-responsive-16by9">
                            <img className="embed-responsive-item" src={irispred}></img>
                            </div>
                        </div>


                        <div className="col-lg-6 col-md-12" style={{marginTop:75, marginBottom:75}}>

                        <p className="h5 text-center mb-4">Alumni Database System</p>
                        <div className="embed-responsive embed-responsive-16by9">
                        <img className="embed-responsive-item" src="https://associationsnow.com/wp-content/uploads/2018/04/GettyImages-547533236-600x360.jpg"></img>
                        </div>
                        </div>
                        
                        
                        <div className="col-lg-6 col-md-12 px-4" style={{marginTop:75, marginBottom:75}}>


                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Description</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• Built a full-stack app using Python’s Flask Web-framework, targeted towards current students to gain industry/job role alumni information.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Designed the database with CRUD functionality and wrote stored procedures employing MS SQL Server with 400+ data entries pulled from the web.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Devised a visually appealing and intuitive front-end using Bootstrap, CSS and HTML.</p>
                            </div>
                            </div>


                           


                            <div style={{height: 30}}></div>


                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Technologies Utilized</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• Python, Flask, SQL, HTML5, CSS3</p>
                            </div>
                            </div>


                        </div>

                        
                        <div className="col-lg-6 col-md-12 px-4" style={{marginTop:75, marginBottom:75}}>

                            
                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Description</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• Visualized the dataset comprising of about 662 chest x-rays using Zeppelin Spark.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Performed histogram equalization and multiple data augmentations to enhance the dataset.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Created a deep learning model using the VGG16 architecture and the TensorFlow library running on top of the AWS EC2 instance. It gave us a loss of 2.76 and an accuracy of 82.5% during validation.</p>
                            </div>
                            </div>
                            

                            <div style={{height: 30}}></div>

                            
                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Technologies Utilized</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• AWS, Python, Spark</p>
                            </div>
                            </div>
                            

                        </div>
                    

                        <div className="col-lg-6 col-md-12" style={{marginTop:75, marginBottom:75}}>

                            <p className="h5 text-center mb-4">Triaging TB Patients using Image Classification</p>
                            <div className="embed-responsive embed-responsive-16by9">
                            <img className="embed-responsive-item" src="https://www.ecmwf.int/sites/default/files/Ai-image-deep-net-v3-690px.jpg"></img>
                            </div>
                        </div>


                        <div className="col-lg-6 col-md-12" style={{marginTop:75, marginBottom:75}}>

                            <p className="h5 text-center mb-4">Loan Default Prediction</p>
                            <div className="embed-responsive embed-responsive-16by9">
                            <img className="embed-responsive-item" src="https://i2.wp.com/www.upbuild.io/wp-content/uploads/2018/07/upbuild-what-are-your-analytics-telling-you.png?fit=1024%2C550&ssl=1"></img>
                            </div>
                        </div>
                        
                        
                        <div className="col-lg-6 col-md-12 px-4" style={{marginTop:75, marginBottom:75}}>

                            
                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Description</h5>
                                <p className="grey-text" style={{textAlign: "left"}}>• Cleaned the Lending Club loan dataset 2007-2011, selected significant factors from 121 variables, under-sampled the dataset to balance out good and bad loans.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• Tested seven different models using a decision tree, bagging, random forest, boosting, logit, lasso and naïve Bayes.</p>
                                <p className="grey-text" style={{textAlign: "left"}}>• The best model turned out to be Boosting with the highest AUC of 0.73.</p>
                            </div>
                            </div>
                            

                           
                            

                            <div style={{height: 30}}></div>

                            
                            <div className="row">
                            <div className="col-1 mr-3">
                                
                            </div>
                            <div className="col-10">
                                <h5 className="feature-title">Technologies Utilized</h5>
                                <p className="grey-text"style={{textAlign: "left"}}>• Python (Numpy, Pandas, Sklearn, Matplotlib)</p>
                            </div>
                            </div>
                            

                        </div>
                    
                    

                    </div>
                    

                </section>
            

                <hr className="my-5"/>

            
                <section >

                    <h2 id="workex" className="my-5 h3 text-center">Work Experience</h2>

                    
                    <div className="row features-small mb-5 mt-3 wow fadeIn">

                    
                        <div className="col-md-4" >
                            

                            <div className="row">

                            
                            <div className="col-10" >
                                <h6 className="feature-title"><strong>Graduate Assistant</strong></h6>
                                <h6 className="feature-title">University of Maryland - College Park</h6>
                                <p className="grey-text">Jan 2019 - Dec 2019</p>
                                <div style={{height: 15}}></div>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Worked with the Department of Chemical and Biomolecular Engineering as a Software Developer Graduate Assistant.</h6>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Slashed 75% manual work by creating a web-enabled scholarship application system using Python, Django Web Framework and SQL for the department’s website.</h6>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Improved query times by 50% for the department’s database with 20+ years of alumni data.</h6>
                                <div style={{height: 15}}></div>
                            </div>


                            </div>
                            
                            
                        </div>
                        
                        
                        <div className="col-md-4" >
                            

                        <div className="row">
                            
                            <div className="col-10" style={{marginLeft:50}}>
                                <h6 className="feature-title"><strong>Software Engineer</strong></h6>
                                <h6 className="feature-title">Omega Nutraceuticals Pvt. Ltd.</h6>
                                <p className="grey-text">Sep 2016 - May 2018</p>
                                <div style={{height: 15}}></div>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Used quantitative data to achieve an understanding of consumer behavior, demographics and lifecycle.</h6>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Developed a reporting system using Python and SQL to provide bar charts and scatterplot outputs to easily visualize the impact of specific business decisions on attributes such as sales, price and units.</h6>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Improved operation team’s productivity by ~30% and increased overall profit by ~25%.</h6>
                                <div style={{height: 15}}></div>
                            </div>


                            </div>
                            

                        </div>
                        
                        
                        <div className="col-md-4" >
                            

                        <div className="row">
                            
                            <div className="col-10" style={{marginLeft:50}}>
                                <h6 className="feature-title"><strong>Software Engineer Intern</strong></h6>
                                <h6 className="feature-title">Ypsilon IT Solutions Pvt. Ltd.</h6>
                                <p className="grey-text">Jun 2016 - Aug 2016</p>
                                <div style={{height: 15}}></div>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Built a full-stack e-commerce website coupled with a responsive front-end using ReactJS; Incorporated search and commenting functionalities using NodeJS, Express and MongoDB.</h6>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Introduced features such as user authentication and session management for secure payments.</h6>
                                <div style={{height: 15}}></div>
                                <h6 className="feature-title">• Incorporated client-side features and reduced server-side validations for improved efficiency and turn-around times.</h6>
                                <div style={{height: 15}}></div>
                            </div>


                            </div>
                            

                        </div>
                    

                    </div>
                    

                </section>
            

                <hr className="mb-5"/>

            
                <section>

                    <h2 id="education" className="my-5 h3 text-center">Education</h2>


                    <div className="row row-cols-1 row-cols-md-2">


                        <div className="col mb-4">

    
                            <div className="card h-100">

      
                                <div className="view overlay">
                                    <img className="card-img-top" src="https://globalmaryland.umd.edu/sites/default/files/styles/office_slider_style/public/hero-slideshow/Moved.jpg?itok=YB5BgaxR"
                                    alt="Card image cap" style= {{height:346.22}}/>        
                                </div>

      
                                <div className="card-body">
                                    <h4 className="card-title">University of Maryland - College Park</h4>
                                    <p>Aug 2018 - Dec 2019</p>
                                    <p><b>Master of Science, Information Systems</b></p>
                                </div>


                            </div>
    
                        </div>


                        <div className="col mb-4">
    
                            <div className="card h-100">

      
                                <div className="view overlay">
                                    <img className="card-img-top" src="https://media-exp1.licdn.com/dms/image/C4E1BAQG23I48Un0Ebw/company-background_10000/0?e=2159024400&v=beta&t=dx7sY56Q5LxYvZ_654pjmUzBB4T2BfLryJvmGEcKxKY"
                                    alt="Card image cap" style= {{height:346.22}}/>
                                </div>

      
                                <div className="card-body">
                                    <h4 className="card-title">Shri G. S. Institute of Technology and Science</h4>
                                    <p>Aug 2014 - May 2018</p>
                                    <p><b>Bachelor of Engineering, Electronics and Telecommunications</b></p>
                                </div>

                            </div>
    
                        </div>
 

                    </div>


                </section>
            

            </div>


        </main>
        

        
        <footer className="page-footer text-center font-small mt-4 wow fadeIn">

            
            <div className="pt-4">
            <h2 id="contactdetails" className="my-5 h3 text-center">Contact Details</h2>
            <p><b>Email:</b> ishani.bhansali@marylandsmith.umd.edu</p>
            <p><b>Ph no.:</b> +1 (240) 918-9352</p>
            </div>
            

            <hr className="my-4"/>

            
            <div className="pb-4">


            <a href="https://www.linkedin.com/in/ishanibhansali/" target="_blank">
                <i className="fab fa-linkedin mr-3"></i>
            </a>

            <a href="https://github.com/ishanibhansali" target="_blank">
                <i className="fab fa-github mr-3"></i>
            </a>

            </div>
            

            
            <div className="footer-copyright py-3">
            <b>© 2020 Copyright:</b> Ishani Bhansali
            </div>
            

        </footer>
    </div>
  );
}

export default App;
